import Box from "@amzn/meridian/box";
import Input from "@amzn/meridian/input";
import {useState} from "react"
import Heading from "@amzn/meridian/heading";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import {CartInput} from "../common/CartInput";
import Text from "@amzn/meridian/text"
import {FCMenuDecoder} from "../../environment/fcMenu";
import {errrorBeep, successBeep} from "../util/beep";
import Loader from "@amzn/meridian/loader"
import Select, { SelectOption } from "@amzn/meridian/select"
import {CartState, CartStateDropdown} from "../util/Constants";
import Alert from "@amzn/meridian/alert";
import Row from "@amzn/meridian/row";
import React from "react";
import {HeiseServiceRepo} from "../../repo/heiseServiceRepo";
import Toaster from "@amzn/meridian/toaster";
import Modal, {ModalFooter} from "@amzn/meridian/modal";

enum SiteAuditMode {
    INITIALIZATION,
    SCANNING,
    MISMATCH
}

export interface SiteAuditProps {
    heiseServiceRepo: HeiseServiceRepo,
}

let toastIdCount = 0

export function SiteAudit(props: SiteAuditProps) {
    const userId = FCMenuDecoder.decodeFcMenuCookie()?.employeeLogin ?? 'unknown'
    const [siteId, setSiteId] = useState<string>(FCMenuDecoder.decodeFcMenuCookie()?.warehouseId ?? "")
    const [mode, setMode] = useState<SiteAuditMode>(SiteAuditMode.INITIALIZATION)
    const [loading, setLoading] = useState(false)
    const [cartState, setCartState] = useState<string>("")
    const [cartId, setCartId] = useState<string>("")
    const [beaconPresent, setBeaconPresent] = useState(undefined)

    const [beaconMatchAAP, setBeaconMatchAAP] = useState<string | undefined>(undefined)
    const [printedBeaconBarcode, setPrintedBeaconBarcode] = useState<string | undefined>("")

    const [toasts, setToasts] = useState<{id: string, timeout: number, message: string}[]>([])
    const onCloseToast = (id: string) => setToasts(toasts.filter(t => t.id !== id))
    const onOpenToast = (message: string) => setToasts(toasts.concat({ id: `${++toastIdCount}`, timeout: 3000, message: message }))
    const [resetCartId, setResetCartId] = useState<boolean>(false)
    const [recentCartsScanned, setRecentCartsScanned] = useState<string[]>([])

    const [showSummaryCountModal, setShowSummaryCountModal] = useState<boolean>(false)
    const [summaryBucket, setSummaryBucket] = useState<Map<string, string[]>>(new Map());
    const addToSummaryBucket = (key: string, value: string) => {
        let cartList = summaryBucket.get(key) || []
        setSummaryBucket(new Map(summaryBucket.set(key, [value, ...cartList])));
    };

    const initialSetup = () => (
        <Column>
            <Input
                id="siteId"
                value={siteId}
                onChange={(value) => setSiteId(value.toUpperCase())}
                type="text"
                placeholder="Enter Your Site ID (i.e. MSP1)"
            />
            <Button size={"large"} onClick={() => setMode(SiteAuditMode.SCANNING)} disabled={siteId.length !== 4}>Start</Button>
        </Column>
    )

    const onSubmit = async (beaconInfoValidated: boolean) => {
        setLoading(true)
        if(recentCartsScanned.includes(cartId)){
            onOpenToast("This cart has already been scanned recently")
            setLoading(false)
            return
        }
        try {
            const retVal = await props.heiseServiceRepo.cartAuditEvent({
                timestamp: new Date(),
                nodeId: siteId,
                cartId: cartId,
                //other is considered available so we convert it before it gets to the backend
                operationalState: cartState === CartState[CartState.OTHER] ? CartState[CartState.AVAILABLE] : cartState,
                userId: userId,
                beaconPresent: !beaconPresent ? beaconPresent : beaconPresent === "TRUE",
                isPrintedBeaconBarcodeCorrect: beaconMatchAAP,
                beaconInfoValidated: beaconInfoValidated
            })
            const nodeIdMatches = retVal.nodeIdMatches
            setPrintedBeaconBarcode(retVal?.printedBeaconBarcode)
            if(nodeIdMatches){
                setRecentCartsScanned(prevState => [cartId, ...prevState]);
                addToSummaryBucket(cartState, cartId)
                resetFields()
                successBeep()
            }
            else if(!nodeIdMatches && !beaconInfoValidated){
                errrorBeep()
                setMode(SiteAuditMode.MISMATCH)
            }
            else if(!nodeIdMatches && beaconInfoValidated) {
                setRecentCartsScanned(prevState => [cartId, ...prevState]);
                addToSummaryBucket(cartState, cartId)
                resetFields()
                successBeep()
                setMode(SiteAuditMode.SCANNING)
            }
            setLoading(false)
        }
        catch (error: any){
            errrorBeep()
            if (error instanceof Error) {
                onOpenToast(error.message)
            }
            else {
                onOpenToast(error.toString())
            }
            setLoading(false)
        }

    }

    const resetFields = () => {
        setResetCartId(prevState => !prevState)
        setCartId("")
        setBeaconPresent(undefined)
        setBeaconMatchAAP(undefined)
    }

    const scanningMode = () => (
        <Column>
            <CartInput
                onValidCartId={setCartId}
                clearAfterSuccessfulScan={false}
                resetCartId={resetCartId}
            />
            <Select
                value={cartState}
                size="large"
                onChange={setCartState}
                placeholder="Select Cart State..."
            >
                {
                    CartStateDropdown.map((item, index) => {
                        return <SelectOption key={index} value={item.value} label={item.label} />
                    })
                }
            </Select>
            <Button onClick={() => onSubmit(false)} disabled={loading || cartId === ""}>{loading ? <Loader size={"medium"} /> : "Submit"}</Button>
        </Column>
    )

    const mismatchMode = () => (
        <Column>
            <Alert type={"warning"} toast={true}>We found an issue with this cart</Alert>
            <Row>
                <Text>Does this cart</Text>
                <Text type={"h100"}>{cartId}</Text>
                <Text>have a beacon?</Text>
            </Row>
            <Row alignmentHorizontal={"justify"}>
                <Select
                    value={beaconPresent}
                    onChange={setBeaconPresent}
                    width={"100%"}
                >
                    <SelectOption value={"TRUE"} label="YES" />
                    <SelectOption value={"FALSE"} label="NO" />
                </Select>
            </Row>
            {
                beaconPresent === "TRUE" && printedBeaconBarcode && (
                    <Column>
                        <Row>
                            <Text>Is this the beacon? </Text>
                            <Text type={"h100"}>{printedBeaconBarcode}</Text>
                        </Row>
                        <Select
                            value={beaconMatchAAP}
                            onChange={setBeaconMatchAAP}
                            width={"100%"}
                        >
                            <SelectOption value="TRUE" label="YES" />
                            <SelectOption value="FALSE" label="NO" />
                            <SelectOption value="UNREADABLE" label="UNREADABLE" />
                        </Select>
                    </Column>
                )
            }
            <Button onClick={() => onSubmit(true)} disabled={loading}>{loading ? <Loader size={"medium"} /> : "Submit"}</Button>
        </Column>
    )

    return (
        <Box spacingInset="100 300">
            <Toaster toasts={toasts} onCloseToast={onCloseToast}>
                {toast => <Alert type={"error"} toast={true}>{toast.message}</Alert>}
            </Toaster>
            <Heading level={2} alignment={"center"}>Audit Carts At A Site</Heading>
            <Modal
                title="Cart State Count"
                open={showSummaryCountModal}
                onClose={() => setShowSummaryCountModal(false)}
                scrollContainer="viewport"
                closeLabel="Close"
                aria-describedby="modal-description"
            >
                <Column width="100%">
                    {CartStateDropdown.map((value, index) => (
                        <Row alignmentHorizontal={"justify"}>
                            <Text type="b500">{value.label}</Text>
                            <Text type="h600">{summaryBucket.get(value.value)?.length || 0}</Text>
                        </Row>
                    ))}
                </Column>
                <ModalFooter>
                    <Row alignmentHorizontal="end" widths="fit">
                        <Button type="secondary" onClick={() => setShowSummaryCountModal(false)}>
                            Cancel
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>

            {mode === SiteAuditMode.INITIALIZATION && initialSetup()}
            {mode === SiteAuditMode.SCANNING && scanningMode()}
            {mode === SiteAuditMode.MISMATCH && mismatchMode()}
            {mode !== SiteAuditMode.INITIALIZATION &&
                <Box type={"fill"} spacingInset={"400"}>
                    <Row alignmentHorizontal={"justify"}>
                        <Text>Recent:</Text>
                        <Button type="tertiary" onClick={() => setShowSummaryCountModal(true)}>Show State Count</Button>
                    </Row>
                    {recentCartsScanned.slice(0, 9).map((value, index) => (
                        <Text key={index}>{value}</Text>
                    ))}
                </Box>}
        </Box>
    )
}